import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Story = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
          align={'center'}
        >
          Our story
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
        >
          Building Your Dreams with 
          <br />
          Experience, Quality, and Care
        </Box>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'textSecondary'}
          align={'center'}
        >
          BroCher Builds is a family-owned and operated remodeling company based in 
          Columbus, OH. Founded by brothers who share a passion for construction and 
          remodeling, our company has grown over the years thanks to our commitment 
          to quality workmanship, attention to detail, and personalized service. 
          With over a decade of experience in the industry, we&apos;ve built a reputation 
          as a trusted and reliable choice for homeowners looking to upgrade their 
          homes. Our team takes pride in every project we undertake, whether it&apos;s a 
          small kitchen remodel or a large-scale home renovation. We&apos;re committed to 
          exceeding our clients&apos; expectations and helping them turn their remodeling 
          dreams into a reality.
        </Typography>
      </Box>
    </Box>
  );
};

export default Story;
