/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { PictureBottom } from './components';

const WhoWeAre = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
          align={'center'}
        >
          Who we are
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
        >
          BroCher Builds: Your Versatile
          <br />
          Remodeling Solution in Central Ohio
        </Box>
        <Typography
          variant={'h6'}
          component={'p'} 
          color={'textSecondary'}
          align={'center'}
        >
          BroCher Builds is a Columbus-based construction company that offers versatile construction and remodeling services in central Ohio. 
          From flooring to painting, kitchen to bathroom renovations, and everything in between, we can handle it all. 
          With a focus on personalized service, attention to detail, and quality workmanship, we work closely with 
          clients to ensure their complete satisfaction. Whether you're looking to update your home's interior or 
          exterior, BroCher Builds has the skills and experience to make your dream a reality.
        </Typography>
        {/** Contact Us button */}
        <Box marginTop={3} display={'flex'} justifyContent={'center'}>
          <Button
            component={'a'}
            href={'/contact'}
            variant="contained"
            color="primary"
            size="large"
            endIcon={
              <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            }
          >
            Contact Us
          </Button>
        </Box>
      </Box>
      {/** Illustration */}
      <Box>
        <PictureBottom />
      </Box>
    </Box>
  );
};

export default WhoWeAre;
