import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from 'svg/img/logo.png';
import Grid from '@mui/material/Grid';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';

const Topbar = ({
  onSidebarOpen,
}) => {
  return (
    <Grid container spacing={4}
      display={'flex'}
      alignItems={'center'}
      width={'100%'}

    >
      {/** This grid element holds the logo and other stuff, it appear above the navigation links */}
      <Grid item container alignItems={'center'} justifyContent={'space-between'} xs={12} md={12}>
        {/** Hamburger menu */}
        <Box display={'flex'} alignItems={'center'}>
          <Box marginRight={{ xs: 1, sm: 2 }} sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton onClick={onSidebarOpen} aria-label="Menu">
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
        {/** Logo */}
        <Box
          display={'flex'}
          alignItems="baseline"
          component="a"
          underline="none"
          href="/"
          title="bcb"
          width={'40%'}
          height={'undefined'}
          aspectRatio={1}
          zIndex={1}
        >
          <Box ><img style={{ maxWidth: '100%' }} src={Logo} alt="BCB Logo" /></Box>
        </Box>
        {/** Dark mode toggler */}
        <Box display="flex" alignItems={'center'}>

        </Box>
      </Grid>
      {/** This Grid element makes the bottons appear below the logo */}
      <Grid item container sx={{ display: { xs: 'none', md: 'flex' } }} justifyContent={'center'} alignItems={'center'} xs={12} md={12} marginBottom={4}>
        <Box marginX={4}>
          <Link
            underline="none"
            component="a"
            href="/"
            color="textPrimary"
          >
            Home
          </Link>
        </Box>
        <Box marginX={4}>
          <PopupState variant="popover" popupId="demoMenu">
            {(popupState) => (
              <React.Fragment>
                <Link
                  underline="none"
                  component="a"
                  style={{ cursor: 'pointer' }}
                  color="textPrimary"
                  variant="contained" {...bindHover(popupState)}
                >
                  Portfolio
                </Link>
                <HoverMenu
                  {...bindMenu(popupState)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <MenuItem onClick={popupState.close}>
                    <Link underline="none" component="a" href="/portfolio/ohio" color="textPrimary">
                      Ohio
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={popupState.close}>
                    <Link underline="none" component="a" href="/portfolio/michigan" color="textPrimary">
                      Michigan
                    </Link>
                  </MenuItem>
                </HoverMenu>
              </React.Fragment>
            )}
          </PopupState>
        </Box>
        <Box marginX={4}>
          <Link
            underline="none"
            component="a"
            href="/about"
            color="textPrimary"
          >
            About Us
          </Link>
        </Box>
        <Box marginX={4}>
          <Link
            underline="none"
            component="a"
            href="/contact"
            color="textPrimary"
          >
            Contact Us
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Topbar;
