/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const About = () => {

  return (
    <Box>
      <Box marginBottom={{ xs: 2, sm: 3, md: 4 }}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'primary'}
          align={'center'}
        >
          Exterior
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
          gutterBottom
          data-aos="fade-up"
        >
          Exterior Gallery
        </Box>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'textSecondary'}
          align={'center'}
          data-aos="fade-up"
        >
          From decks and patios to fences and gazebos, we 
          can help you create an outdoor living space 
          that's perfect for your needs. Our team can work 
          with you to design and build a space that's both 
          functional and beautiful, using durable materials 
          and expert craftsmanship.
        </Typography>
        <Box
          marginTop={2}
          display={'flex'}
          justifyContent={'center'}
          data-aos="fade-up"
        >
          <Button color={'primary'} variant={'contained'} size={'large'} href={'/contact'}>
            Contact us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
