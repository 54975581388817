import React, { useState } from 'react';
import Slider from 'react-slick';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import useMediaQuery from '@mui/material/useMediaQuery';

const photos = [
  {
    src: 'https://i.ibb.co/GRFWHLy/i-Stock-1396856251.jpg',
    title: 'House Exterior',
  },
  {
    src: 'https://i.ibb.co/qND27dV/i-Stock-1440545041.jpg',
    title: 'Sauna',
  },
  {
    src: 'https://i.ibb.co/gmM4tFT/i-Stock-1307122988.jpg',
    title: 'Basement',
  },
  {
    src: 'https://i.ibb.co/3YxkbLw/i-Stock-1452558775.jpg',
    title: 'Bathroom',
  },
  {
    src: 'https://i.ibb.co/6wWWYH7/i-Stock-1295727923.jpg',
    title: 'Mudroom',
  },
  {
    src: 'https://i.ibb.co/4YpSBnt/i-Stock-1455250006.jpg',
    title: 'Patio',
  },
  {
    src: 'https://i.ibb.co/GWbkGyK/i-Stock-1456822664.jpg',
    title: 'Living Room',
  },
  {
    src: 'https://i.ibb.co/GMDfSgT/i-Stock-1256574745.jpg',
    title: 'Kitchen',
  },
  {
    src: 'https://i.ibb.co/92Q0Bx2/i-Stock-1452558897.jpg',
    title: 'Shower',
  },
  {
    src: 'https://i.ibb.co/nM4CYxQ/i-Stock-1363800263.jpg',
    title: 'Firepit',
  },
  {
    src: 'https://i.ibb.co/QFTRWCD/i-Stock-1396671206.jpg',
    title: 'Sauna',
  },
  {
    src: 'https://i.ibb.co/V9Fy2BC/i-Stock-1262572397.jpg',
    title: 'Laundry',
  },
  {
    src: 'https://i.ibb.co/CBZV6PD/i-Stock-1453223696.jpg',
    title: 'Shower',
  },
  {
    src: 'https://i.ibb.co/0M72LVz/i-Stock-1398693398.jpg',
    title: 'Kitchen',
  },
  {
    src: 'https://i.ibb.co/Wg1pXyV/i-Stock-1272128530.jpg',
    title: 'Front Porch',
  },
  {
    src: 'https://i.ibb.co/Vp6sf80/i-Stock-1263778913.jpg',
    title: 'Basement',
  },
];

const Gallery = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  let slidesToShow = 1;
  if (isXs) {
    slidesToShow = 1;
  }
  if (isSm) {
    slidesToShow = 2;
  }
  if (isMd) {
    slidesToShow = 3;
  }
  if (isLg) {
    slidesToShow = 4;
  }

  const sliderOpts = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
          align={'center'}
        >
          Gallery
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
          data-aos={'fade-up'}
        >
          Enjoy our gallery
        </Box>
      </Box>
      <Slider {...sliderOpts}>
        {photos.map((item, i) => (
          <Box key={i} paddingX={2}>
            <Box
              component={Card}
              width={'100%'}
              height={'100%'}
              borderRadius={3}
              onClick={() => openLightbox(i)}
              sx={{
                cursor: 'pointer',
              }}
            >
              <CardMedia
                image={item.src}
                title={item.title}
                sx={{
                  height: 340,
                }}
              />
            </Box>
          </Box>
        ))}
      </Slider>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Box>
  );
};

export default Gallery;
