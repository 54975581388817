import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

const WhatWeDo = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Grid container spacing={isMd ? 8 : 4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'medium',
              }}
              gutterBottom
              color={'textSecondary'}
              align={'center'}
            >
              Portfolio
            </Typography>
            <Box
              component={Typography}
              fontWeight={700}
              variant={'h3'}
              gutterBottom
              align={'center'}
            >
              Select your location
            </Box>
            <Typography
              variant={'h6'}
              component={'p'}
              color={'textSecondary'}
              align={'center'}
            >
              Bro Cher Builds is proud to operate in two locations: Central Ohio and the Northern Peninsula of Michigan!
              Select your location below to see some of our previous work and our offerings in your area.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box marginTop={3} display={'flex'} style={{ width: '100%', height: 150 }}>
              <Box
                style={{ width: '100%', height: 150 }}
                component={Button}
                variant="contained"
                color="primary"
                href='/portfolio/ohio'

              >
                <Typography
                  variant={'h2'}
                  component={'p'}
                  align={'center'}
                >
                  Ohio
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Box marginTop={3} display={'flex'} style={{ width: '100%', height: 150 }}>
              <Box
                style={{ width: '100%', height: 150 }}
                component={Button}
                variant="contained"
                color="primary"
                href='/portfolio/michigan'

              >
                <Typography
                  variant={'h2'}
                  component={'p'}
                  align={'center'}
                >
                  Michigan
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default WhatWeDo;
