import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import WithLayout from 'WithLayout';
// Available layouts
import {
  Main as MainLayout,
} from './layouts';

// Landing pages
import {
  Home as HomeView,
} from './views/landingPages';

// Supporting pages
import {
  About as AboutView,
  Portfolio as PortfolioView,
  PortfolioOhio as PortfolioOhioView,
  PortfolioMichigan as PortfolioMichiganView,
  Contact as ContactView,
  Pricing as PricingView,
  Customers as CustomersView,
  HireUs as HireUsView,
  Faq as FaqView,
  FormSent as FormSentView,
  Privacy as PrivacyView,
  ComingSoon as ComingSoonView,
  MaintenanceMode as MaintenanceModeView,
  Cover as CoverView,
  NotFound as NotFoundView,
  QR as QrView
} from './views/supportingPages';

// Portfolio gallery pages
import {
  Basements as BasementsView,
  Bathrooms as BathroomsView,
  Exterior as ExteriorView,
  Flooring as FlooringView,
  Kitchens as KitchensView,
  Laundry as LaundryView,
  More as MoreView,
  Painting as PaintingView,
  Fireplaces as FireplacesView,
  StoneAndBrick as StoneAndBrickView,
  Foundations as FoundationsView,
  Framing as FramingView,
} from './views/supportingPages/Portfolio/gallery';



const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={HomeView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/ohio/basements"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={BasementsView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/ohio/bathrooms"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={BathroomsView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/ohio/exterior"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ExteriorView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/ohio/flooring"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={FlooringView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/ohio/kitchens"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={KitchensView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/ohio/laundry"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={LaundryView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/ohio/more"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={MoreView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/ohio/painting"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={PaintingView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/michigan/fireplaces"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={FireplacesView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/michigan/stoneandbrick"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={StoneAndBrickView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/michigan/foundations"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={FoundationsView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/michigan/framing"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={FramingView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/about"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={AboutView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={PortfolioView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/ohio"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={PortfolioOhioView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio/michigan"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={PortfolioMichiganView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/contact"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ContactView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-pricing"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={PricingView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-customers"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={CustomersView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-hire-us"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={HireUsView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-faq"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={FaqView} layout={MainLayout} />
        )}
      />
      <Route
        exact
        path="/form-sent"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={FormSentView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-privacy"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={PrivacyView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-coming-soon"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ComingSoonView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-maintenance-mode"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={MaintenanceModeView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-cover"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={CoverView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/qr"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={QrView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-not-found"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={NotFoundView}
            layout={MainLayout}
          />
        )}
      />
      <Redirect to="/page-not-found" />
    </Switch>
  );
};

export default Routes;
