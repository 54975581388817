import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import { createBrowserHistory } from 'history';


const QR = () => {

  const browserHistory = createBrowserHistory();
  //Forwards the /qr webpage to the webpage given below. Use this to make a dynamic qr code
  browserHistory.push('/portfolio');
  browserHistory.go('/portfolio');


  return (
    <Box marginTop={8}>
      <Container>
        <Typography
          variant={'h6'}
          gutterBottom
          sx={{ fontWeight: 500 }}
          align={'center'}>
          QR
        </Typography>
      </Container>
    </Box>
  );
};

export default QR;
