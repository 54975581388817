import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Vilam from 'svg/img/employees/william.jpg';
import Kamila from 'svg/img/employees/kamila.jpg';
import David from 'svg/img/employees/david.jpg';
import Oleg from 'svg/img/employees/oleg.jpg';
import Dina from 'svg/img/employees/dina.jpg';
import Maks from 'svg/img/employees/maks.jpg';
import Taras from 'svg/img/employees/taras.jpg';
import Vika from 'svg/img/employees/vika.jpeg';

const Team = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
          align={'center'}
        >
          Our team
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
        >
          Trust the professionals
        </Box>
      </Box>
      <Grid container spacing={2}>
        {[
          {
            name: 'David',
            title: 'Founder',
            avatar: David,
            about:
              'In my spare time, you\'ll often find me out in nature, casting a line into the waters or tracking game.',
          },
          {
            name: 'Maks',
            title: 'Co-Founder',
            avatar: Maks,
            about:
              'I am a huge fan of classic cars and love going to car shows whenever I get the chance.',
          },
          {
            name: 'Oleg',
            title: 'Painter',
            avatar: Oleg,
            about:
              'I am a huge fan of sushi and can eat it every day without getting tired of it!',
          },
          {
            name: 'William',
            title: 'Lead Carpenter',
            avatar: Vilam,
            about:
              'Outside of work, I enjoy unwinding with a good book or honing my culinary skills in the kitchen.',
          },
          {
            name: 'Taras',
            title: 'Countertop Fab/Installer',
            avatar: Taras,
            about:
              'When I\'m not working, you can find me exploring new restaurants in the city or perfecting my grilling skills outdoors.',
          },
          {
            name: 'Kamila',
            title: 'Marketing',
            avatar: Kamila,
            about:
              'With a love for design, travel, and music, I\'m constantly inspired to explore new creative horizons.',
          },
          {
            name: 'Dina',
            title: 'Accounting',
            avatar: Dina,
            about:
              'Golfing and globetrotting are my passions, always on the lookout for new fairways and distant destinations.',
          },
          {
            name: 'Vika',
            title: 'Administrator',
            avatar: Vika,
            about:
              'When I\'m not focused on work, I\'m out on hiking trails or capturing the beauty of nature through my photography.',
          },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i}>
            <Box
              component={Card}
              borderRadius={3}
              boxShadow={2}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <CardContent>
                <Box
                  component={Avatar}
                  src={item.avatar}
                  height={80}
                  width={80}
                />
                <Box marginTop={4} height={{sm: 140, md: 160, lg: 140}}>
                  <ListItemText primary={item.name} secondary={item.title} />
                  <Typography variant={'subtitle2'} color={'textSecondary'}>
                    {item.about}
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
