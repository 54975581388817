import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { colors } from '@mui/material';

const Process = ({ themeMode = 'light' }) => {
  return (
    <Box>
      <Box marginBottom={4} >
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
          align={'center'}
        >
          Our Process
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
        >
          From Consultation to Completion
        </Box>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'textSecondary'}
          align={'center'}
        >
          At BroCher Builds, we believe that a successful home renovation project 
          starts with a clear and well-defined process. From the initial 
          consultation to the final walkthrough, we work closely with our clients 
          to ensure that every aspect of the project meets their needs and exceeds 
          their expectations. Our team of skilled professionals is dedicated to 
          delivering high-quality workmanship, excellent communication, and 
          outstanding customer service throughout every step of the process. When 
          you choose BroCher Builds, you can trust that your home renovation project 
          is in good hands.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {[
          {
            title: 'Idea',
            subtitle:
              'We meet with your team to know more about your project idea and goals. After that, our team will work together to create an action plan and proposal for your project.',
            icon: (
              <svg
                width={40}
                height={40}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                />
              </svg>
            ),
            color: colors.amber,
          },
          {
            title: 'Design',
            subtitle:
              'Our expert team collaborates with you to turn your ideas into a custom plan that fits your vision, budget, and timeline',
            icon: (
              <svg
                width={40}
                height={40}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
              </svg>
            ),
            color: colors.purple,
          },
          {
            title: 'Construction',
            subtitle:
              'Our experienced construction team brings the design to reality with precision, attention to detail, and the highest quality craftsmanship.',
            icon: (
              <svg
                width={40}
                height={40}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                />
              </svg>
            ),
            color: colors.green,
          },
        ].map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box color={item.color[themeMode === 'light' ? 500 : 700]}>
              {item.icon}
            </Box>
            <Box
              component={Typography}
              variant={'h6'}
              gutterBottom
              fontWeight={500}
            >
              {item.title}
            </Box>
            <Typography color="text.secondary">{item.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

Process.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Process;
