/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Headline = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <Box marginBottom={50}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: 'georgia',
              fontWeight: 700,
              color: theme.palette.common.white,
            }}
          >
            {/** Larger text goes here */}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h4"
            color={'#b5b5b5'}
            sx={{
              fontWeight: 700,
            }}
          >
            {/** smaller text goes here */}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Headline;
