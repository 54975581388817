import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Photo1 from 'svg/img/gallery/exterior/photo1.jpg';
import Photo2 from 'svg/img/gallery/exterior/photo2.jpg';
import Photo3 from 'svg/img/gallery/exterior/photo3.jpg';
import Photo4 from 'svg/img/gallery/exterior/photo4.jpg';
import Photo5 from 'svg/img/gallery/exterior/photo5.jpg';
import Photo6 from 'svg/img/gallery/exterior/photo6.jpg';
import Photo7 from 'svg/img/gallery/exterior/photo7.jpg';
import Photo8 from 'svg/img/gallery/exterior/photo8.jpg';
import Photo9 from 'svg/img/gallery/exterior/photo9.jpg';
import Photo10 from 'svg/img/gallery/exterior/photo10.jpg';
import Photo11 from 'svg/img/gallery/exterior/photo11.jpg';
import Photo12 from 'svg/img/gallery/exterior/photo12.jpg';

const Gallery = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: Photo1,
      rows: 2,
      cols: 1,
    },
    {
      src: Photo2,
      rows: 1,
      cols: 2,
    },
    {
      src: Photo3,
      rows: 1,
      cols: 1,
    },
    {
      src: Photo4,
      rows: 1,
      cols: 1,
    },
    {
      src: Photo5,
      rows: 1,
      cols: 2,
    },
    {
      src: Photo6,
      rows: 1,
      cols: 1,
    },
    {
      src: Photo7,
      rows: 1,
      cols: 1,
    },
    {
      src: Photo8,
      rows: 1,
      cols: 1,
    },
    {
      src: Photo9,
      rows: 1,
      cols: 1,
    },
    {
      src: Photo10,
      rows: 1,
      cols: 1,
    },
    {
      src: Photo11,
      rows: 1,
      cols: 1,
    },
    {
      src: Photo12,
      rows: 1,
      cols: 1,
    },
  ];

  const photosToShow = isMd ? photos.slice(0, 5) : photos.slice(0, 4);

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'} marginBottom={2}>
        <Button
          color="primary"
          size="large"
          endIcon={
            <svg
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          }
          onClick={() => openLightbox(0)}
        >
          Open the gallery
        </Button>
      </Box>
      <Box>
        <ImageList
          variant="quilted"
          cols={4}
          rowHeight={isMd ? 300 : 220}
          gap={isMd ? 16 : 8}
        >
          {photosToShow.map((item, i) => (
            <ImageListItem
              key={i}
              cols={isMd ? item.cols || 1 : 2}
              rows={isMd ? item.rows || 1 : 1}
            >
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  cursor: 'poiner',
                  borderRadius: 4,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Box>
  );
};

export default Gallery;
