import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Fireplaces from 'svg/img/fireplaces.jpg';
import StoneBrick from 'svg/img/stoneBrick.jpg';
import Foundations from 'svg/img/foundations.jpg';
import Framing from 'svg/img/framing.jpg';

const WhatWeDo = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
          align={'center'}
        >
          Portfolio - Michigan
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
        >
          Our Project Gallery:
          <br />
          See Our Exceptional Work
        </Box>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'textSecondary'}
          align={'center'}
        >
          Welcome to our project gallery! Here, you can explore some of our previous work, showcasing 
          high-quality craftsmanship that consistently exceeds our clients&apos; expectations. From 
          captivating fireplaces and enduring stone & brickwork to solid foundations and expert framing, 
          our exceptional results inspire your next home renovation project. Click on one of the panes 
          below to browse through our gallery and get inspired.
        </Typography>
        <Box marginTop={3} display={'flex'} justifyContent={'center'}>
          <Box
            component={Button}
            variant="contained"
            color="primary"
            size="large"
            href='/contact'
            endIcon={
              <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            }
          >
            Contact us
          </Box>
        </Box>
      </Box>
      <Grid container spacing={4}>
        {[
          {
            title: 'Fireplaces',
            subtitle:
              'Elevate your ambiance with our beautifully designed fireplaces, where the flickering flames create a cozy and inviting atmosphere for cherished moments with family and friends.',
            avatar: `${Fireplaces}`,
            link: '/portfolio/michigan/fireplaces',
          },
          {
            title: 'Stone & Brick',
            subtitle:
              'Experience the allure of time-honored craftsmanship in our stone and brickwork, harmoniously blending aesthetics and durability, lending character and distinction to each project.',
            avatar: `${StoneBrick}`,
            link: '/portfolio/michigan/stoneandbrick'
          },
          {
            title: 'Foundations',
            subtitle:
              'At Bro Cher Builds, we lay the groundwork for your dream home with our robust foundations, ensuring a solid and secure base that stands the test of time.',
            avatar: `${Foundations}`,
            link: '/portfolio/michigan/foundations',
          },
          {
            title: 'Framing',
            subtitle:
              'With meticulous attention to detail, our framing expertise ensures the structural integrity of every home, setting the stage for an architecturally remarkable and enduring space.',
            avatar: `${Framing}`,
            link: '/portfolio/michigan/framing',
          },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i} data-aos={'fade-up'}>
            <Box
              component={'a'}
              href={item.link}
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.avatar}
                  loading='lazy'
                  title={item.title}
                  sx={{
                    height: 240,
                  }}
                />
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {item.title}
                  </Box>
                  <Typography
                    align={'left'}
                    variant={'body2'}
                    color="textSecondary"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-end'}>
                  <Button size="small">Explore</Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhatWeDo;
