import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import { WhoWeAre, WhyUs, Highlights, Picture, Gallery} from './components';


const Home = () => {
  const theme = useTheme();
  return (
    <Box >
      <Picture />
      <Container>
        <WhoWeAre />
      </Container>
      <Box bgcolor={theme.palette.alternate.main}>
        <Container>
          <Highlights />
        </Container>
      </Box>
      <Container>
        <WhyUs />
      </Container>
      <Container>
        <Gallery />
      </Container>
    </Box>
  );
};

export default Home;
