import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Workers from 'svg/img/workers.jpg';


const PictureTop = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={2} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={12}
          data-aos={isMd ? 'fade-left' : 'fade-up'}
        >
          <Box component={Card} boxShadow={4} height={'100%'} width={'100%'}>
            <Box
              component={CardMedia}
              height={'100%'}
              width={'100%'}
              minHeight={400}
              image={Workers}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PictureTop;
