/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const About = () => {

  return (
    <Box>
      <Box marginBottom={{ xs: 2, sm: 3, md: 4 }}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'primary'}
          align={'center'}
        >
          Foundations
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
          gutterBottom
          data-aos="fade-up"
        >
          Foundations Gallery
        </Box>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'textSecondary'}
          align={'center'}
          data-aos="fade-up"
        >
          Trust our experienced professionals to build strong and 
          reliable foundations for your dream home. Our precise 
          foundation work is the solid base that keeps your home 
          standing tall. See our gallery to witness their expertise 
          and commitment.
        </Typography>
        <Box
          marginTop={2}
          display={'flex'}
          justifyContent={'center'}
          data-aos="fade-up"
        >
          <Button color={'primary'} variant={'contained'} size={'large'} href={'/contact'}>
            Contact us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
