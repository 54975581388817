import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const CloudHosting = ({ width = 167, height = 120 }) => {
  const theme = useTheme();
  const colorPrimaryMain = theme.palette.primary.main;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 167 120"
    >
      <path
        fill="#E6E6E6"
        d="M83.406 119.106c46.011 0 83.31-4.33 83.31-9.67 0-5.341-37.299-9.67-83.31-9.67s-83.31 4.329-83.31 9.67c0 5.34 37.299 9.67 83.31 9.67z"
        opacity="0.45"
      ></path>
      <path fill="#E6E6E6" d="M136.061 90.5h-28.965v9.185h28.965V90.5z"></path>
      <path
        fill="#C9C9C9"
        d="M147.761 99.846h-40.665v9.185h40.665v-9.185z"
      ></path>
      <path
        fill="#245B5B"
        d="M112.69 14.65H59.385a2 2 0 00-2 2v90.79a2 2 0 002 2h53.305a2 2 0 002-2V16.65a2 2 0 00-2-2z"
      ></path>
      <path
        fill="#fff"
        d="M105.209 19.336H66.754a4.645 4.645 0 00-4.645 4.645v5.955a4.645 4.645 0 004.645 4.645h38.455a4.645 4.645 0 004.645-4.645V23.98a4.645 4.645 0 00-4.645-4.645z"
        opacity="0.07"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M100 9.205H70.83v5.445H100V9.205zM101.15 31.055a4.08 4.08 0 10.001-8.16 4.08 4.08 0 00-.001 8.16z"
      ></path>
      <path fill="#fff" d="M91.035 25.04H67v3.874h24.035V25.04z"></path>
      <path
        fill="#fff"
        d="M105.209 42.506H66.754a4.645 4.645 0 00-4.645 4.645v5.955a4.645 4.645 0 004.645 4.645h38.455a4.645 4.645 0 004.645-4.645V47.15a4.645 4.645 0 00-4.645-4.645z"
        opacity="0.07"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M101.15 54.224a4.08 4.08 0 100-8.159 4.08 4.08 0 000 8.16z"
      ></path>
      <path fill="#fff" d="M91.035 48.21H67v3.876h24.035V48.21z"></path>
      <path
        fill="#fff"
        d="M105.209 65.676H66.754a4.645 4.645 0 00-4.645 4.645v5.955a4.645 4.645 0 004.645 4.645h38.455a4.645 4.645 0 004.645-4.645V70.32a4.645 4.645 0 00-4.645-4.645z"
        opacity="0.07"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M101.15 77.4a4.08 4.08 0 10.001-8.159 4.08 4.08 0 00-.001 8.16z"
      ></path>
      <path fill="#fff" d="M91.035 71.38H67v3.876h24.035V71.38z"></path>
      <path
        fill="#fff"
        d="M105.209 88.846H66.754a4.645 4.645 0 00-4.645 4.645v5.955a4.645 4.645 0 004.645 4.645h38.455a4.645 4.645 0 004.645-4.645V93.49a4.645 4.645 0 00-4.645-4.645z"
        opacity="0.07"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M101.15 100.57a4.08 4.08 0 10.001-8.159 4.08 4.08 0 00-.001 8.159z"
      ></path>
      <path fill="#fff" d="M91.035 94.55H67v3.876h24.035V94.55z"></path>
      <path
        fill="#000"
        d="M67.32 71.725s13.4 3 18.66 12.32-16.31 25.39-16.31 25.39l-14.935.05-3.45-15.615 7.79-11.105 8.245-11.04z"
        opacity="0.08"
      ></path>
      <path
        fill="#FFD200"
        d="M135.449.385h-25.125a4 4 0 00-4 4V20.1a4 4 0 004 4h25.125a4 4 0 004-4V4.385a4 4 0 00-4-4z"
      ></path>
      <path
        fill="#fff"
        d="M132.775 15a2.731 2.731 0 00-1.692-2.528 2.739 2.739 0 00-1.048-.207 2.57 2.57 0 00-.44.04 3.688 3.688 0 00-3.25-1.945h-.135a4.362 4.362 0 00-2.323-5 4.362 4.362 0 00-1.922-.44 4.382 4.382 0 00-4.38 4.38c.001.357.046.713.135 1.06h-.135a3.691 3.691 0 100 7.38h12.81a2.74 2.74 0 002.38-2.74z"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M40 70.066S22.5 68.21 23.165 77.5c.665 9.29 10.5 29 10.5 29h21.5s16.32-25.03 8.38-33.08c-7.94-8.05-9.775-1.165-23.545-3.355z"
      ></path>
      <path
        fill="#DD5E57"
        d="M33.604 109.485l13.09 3.53 11.61-5.89 1.765-3.145H29.744l3.86 5.505z"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M31.566 80.11c1.64-5.5-1.455-11.5-7.32-7.09-4 3-8.78 12.6-9 21.5-.57 20.405 24.46-1.55 27.57-13.236l-3.08-1.82s-5.235 9.77-10.87 11.89c-4.08 1.53.28-3.144 2.7-11.245z"
      ></path>
      <path
        fill="#fff"
        d="M31.566 80.11c1.64-5.5-1.455-11.5-7.32-7.09-4 3-8.78 12.6-9 21.5-.57 20.405 24.46-1.55 27.57-13.236l-3.08-1.82s-5.235 9.77-10.87 11.89c-4.08 1.53.28-3.144 2.7-11.245z"
        opacity="0.46"
      ></path>
      <path
        fill="#fff"
        d="M40 70.066s-2.69 4.24-.05 5.755c2.64 1.515 3.5-.21 3.5-.21s1.965 2.32 3.725.5c1.76-1.82 1.5-5.38-.285-7.035-1.785-1.655-6.89.99-6.89.99z"
      ></path>
      <path
        fill="#F4A28C"
        d="M40.625 70.205c.123.78.202 1.566.235 2.355.035 1.085.38 2.695 2.09 3 2.635.5 3.34-3.68 3.34-3.68s1.655-3.91-1.195-4.445c-2.57-.47-4.995-.815-4.47 2.77z"
      ></path>
      <path
        fill="#000"
        d="M50.79 76.085s-1.634 3.435 2.5 10.5c2.366 4.03-4.925 10.755-4.925 10.755L30.23 99.16l3.41 7.34h21.5s9.45-15.37 10.18-23.28c.73-7.91-14.53-7.135-14.53-7.135z"
        opacity="0.08"
      ></path>
      <path
        fill="#F4A28C"
        d="M46.125 72.06s.15-1.27 1.255-1.215c1.105.055.67 2.42-1.255 1.216zM41.26 71.35s.39-1.216-.64-1.62c-1.03-.406-1.62 1.924.64 1.62z"
      ></path>
      <path
        fill="#FFD200"
        d="M34.46 102.864l-1.515-13.825h23.21l-2.315 14.53.33 1.865-19.955.16.245-2.73z"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M43.804 109.5s4.405 1.54 2.89 3.53c-1.515 1.99-3.905-1.315-3.905-1.315l1.015-2.215z"
      ></path>
      <path
        fill="#000"
        d="M56.155 89.04l-4.6 6.074a23.77 23.77 0 01-10.47 7.855l-6.87 2.625 18.405-.15h1.55l-.33-1.865 2.315-14.54z"
        opacity="0.08"
      ></path>
      <path
        fill="#DD5E57"
        d="M40.626 70.206s-4.27-.615-2.365-3.755c1.905-3.14 2.335-.785 3.235-3.22.9-2.435 1.715-2.905 3.04-1.865 1.325 1.04.04 3.215 2.04 2.605 2-.61 3.365-1.185 3.745.475.38 1.66 0 2.45-1.285 3-1.285.55.735 2.8-2.5 3.71-3.235.91-4.92-2.63-4.92-2.63l-.99 1.68zM43.94 108.714s-17.805-9.09-19.32-4.25c-1.515 4.84 10.75 7.645 18.67 7.935l.65-3.685z"
      ></path>
      <path
        fill="#fff"
        d="M44.78 98.474a1.99 1.99 0 100-3.98 1.99 1.99 0 000 3.98z"
      ></path>
      <path
        fill="#DD5E57"
        d="M48.266 109.429s14.2-14.07 17.11-9.925S57.46 110.054 50 112.74l-1.735-3.31z"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M48.82 110.5s-4.205 3.745-1.77 4.85c2.435 1.105 2.95-2.6 2.95-2.6l-1.18-2.25zM50.79 76.084a6 6 0 015.35-6.585c3.21-.345 7.43-.13 11.18 2.22 7.28 4.56 15.33 8.2 13.345 16.47-1.985 8.27-16.43 11.74-16.43 11.74l-1.93-3.14s12.735-4.3 10.42-11.025c-2.09-6.06-20.565 4.07-21.935-9.68z"
      ></path>
      <path
        fill="#fff"
        d="M50.79 76.084a6 6 0 015.35-6.585c3.21-.345 7.43-.13 11.18 2.22 7.28 4.56 15.33 8.2 13.345 16.47-1.985 8.27-16.43 11.74-16.43 11.74l-1.93-3.14s12.735-4.3 10.42-11.025c-2.09-6.06-20.565 4.07-21.935-9.68z"
        opacity="0.46"
      ></path>
      <path
        fill="#F4A28C"
        d="M62.626 97.314s-4 2.715-2.555 3.465c1.445.75 3.92-1.24 3.92-1.24l-1.365-2.225zM40.25 79.764s1.44-3.525 3.41-3.24c1.97.285-1.105 4.6-1.105 4.6l-2.305-1.36z"
      ></path>
      <path
        fill="#245B5B"
        d="M126.394 16.196l-3.18-4.52-3.185 4.52h1.595V19.7h3.13v-3.505h1.64z"
      ></path>
      <path
        fill="#FFD200"
        d="M33.32 32.61H8.195a4 4 0 00-4 4v15.714a4 4 0 004 4H33.32a4 4 0 004-4V36.61a4 4 0 00-4-4z"
      ></path>
      <path
        fill="#fff"
        d="M30.645 47.235A2.735 2.735 0 0027.91 44.5c-.148 0-.295.014-.44.04a3.687 3.687 0 00-3.25-1.945h-.135a4.38 4.38 0 10-8.5 0h-.135a3.69 3.69 0 000 7.38h12.815a2.74 2.74 0 002.38-2.74z"
      ></path>
      <path
        fill="#245B5B"
        d="M17.904 48.815l3.18 4.515 3.18-4.515H22.67v-3.51h-3.13v3.51h-1.635z"
      ></path>
    </svg>
  );
};

CloudHosting.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CloudHosting;
