import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import {
  WhatWeDo,
} from './components';

const Portfolio = () => {

  return (
    <Box>
      <Container>
        <WhatWeDo />
      </Container>
    </Box>
  );
};

Portfolio.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Portfolio;
