import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Kitchen from 'svg/img/kitchen.jpg';
import Basement from 'svg/img/basement.jpg';
import Bathroom from 'svg/img/bathroom.jpg';
import Laundry from 'svg/img/laundry.jpg';
import Painting from 'svg/img/painting.jpg';
import Flooring from 'svg/img/flooring.jpg';
import Exterior from 'svg/img/exterior.jpg';
import AndMore from 'svg/img/andmore.jpg';

const WhatWeDo = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
          align={'center'}
        >
          Portfolio - Ohio
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
        >
          Our Project Gallery:
          <br />
          See Our Exceptional Work
        </Box>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'textSecondary'}
          align={'center'}
        >
          Welcome to our project gallery! Here you can see some of our previous
          work and get a sense of the high-quality craftsmanship we bring to
          each and every job. From stunning kitchen remodels to beautiful
          outdoor spaces, we take pride in delivering exceptional results that
          exceed our clients&apos; expectations. Click on one of the panes
          below to browse through our gallery and be inspired for your next
          home renovation project.
        </Typography>
        <Box marginTop={3} display={'flex'} justifyContent={'center'}>
          <Box
            component={Button}
            variant="contained"
            color="primary"
            size="large"
            href='/contact'
            endIcon={
              <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            }
          >
            Contact us
          </Box>
        </Box>
      </Box>
      <Grid container spacing={4}>
        {[
          {
            title: 'Kitchens',
            subtitle:
              'From sleek modern designs to cozy traditional kitchens, we\'ve remodeled them all. Our team can help you create a kitchen that\'s both functional and stylish, with custom cabinets, countertops, backsplashes, and more.',
            avatar: `${Kitchen}`,
            link: '/portfolio/ohio/kitchens',
          },
          {
            title: 'Basements',
            subtitle:
              'Don\'t let your basement go to waste - turn it into a functional and comfortable living space for you and your family. We can help you create a basement that\'s perfect for entertaining, working, or relaxing, with custom finishes, lighting, and more.',
            avatar: `${Basement}`,
            link: '/portfolio/ohio/basements'
          },
          {
            title: 'Bathrooms',
            subtitle:
              'Whether you\'re looking to update a small powder room or create a luxurious spa-like bathroom, we have the expertise to bring your vision to life. We specialize in custom tile work, modern fixtures, and innovative storage solutions.',
            avatar: `${Bathroom}`,
            link: '/portfolio/ohio/bathrooms',
          },
          {
            title: 'Laundry',
            subtitle:
              'We understand that laundry rooms are often overlooked, but they can be both practical and stylish. Let us help you design a laundry room that\'s both functional and aesthetically pleasing, with custom cabinets, storage solutions, and efficient appliances.',
            avatar: `${Laundry}`,
            link: '/portfolio/ohio/laundry',
          },
          {
            title: 'Painting',
            subtitle:
              'Our team has a keen eye for detail and the expertise to deliver high-quality painting services that transform any space. From refreshing a room with a new coat of paint to creating custom murals, we are dedicated to bringing your vision to life.',
            avatar: `${Painting}`,
            link: '/portfolio/ohio/painting',
          },
          {
            title: 'Flooring',
            subtitle:
              'We offer a variety of flooring options to suit your style and budget, including hardwood, tile, carpet, and luxury vinyl plank (LVP). Our skilled craftsmen have years of experience in flooring installation and can help you choose the perfect option for your space.',
            avatar: `${Flooring}`,
            link: '/portfolio/ohio/flooring',
          },
          {
            title: 'Exterior',
            subtitle:
              'From decks and patios to fences and gazebos, we can help you create an outdoor living space that\'s perfect for your needs. Our team can work with you to design and build a space that\'s both functional and beautiful, using durable materials and expert craftsmanship.',
            avatar: `${Exterior}`,
            link: '/portfolio/ohio/exterior',
          },
          {
            title: 'And more...',
            subtitle:
              'Our portfolio includes a wide range of other projects. From custom cabinetry to unique lighting solutions, we\'re always up for a new challenge. Check out our portfolio to see what else we\'ve done.',
            avatar: `${AndMore}`,
            link: '/portfolio/ohio/more',
          },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i} data-aos={'fade-up'}>
            <Box
              component={'a'}
              href={item.link}
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.avatar}
                  loading='lazy'
                  title={item.title}
                  sx={{
                    height: 240,
                  }}
                />
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {item.title}
                  </Box>
                  <Typography
                    align={'left'}
                    variant={'body2'}
                    color="textSecondary"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-end'}>
                  <Button size="small">Explore</Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhatWeDo;
