import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Logo from 'svg/img/logo_partial.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedIcon from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';

const Footer = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        {/** Logo */}
        <Box
          display={'flex'}
          alignItems="baseline"
          component="a"
          underline="none"
          href="/"
          title="bcb"
          width={{ xs: '15%', md: '5%' }}
          height={'undefined'}
          aspectRatio={1}
          zIndex={1}
        >
          <Box><img style={{ maxWidth: '100%' }} src={Logo} alt="BCB Logo" /></Box>
        </Box>
        <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
          <IconButton aria-label="instagram" size={'small'} href={'https://www.instagram.com/brocherbuilds/'} target={'blank'}>
            <InstagramIcon />
          </IconButton>
          <IconButton aria-label="facebook" size={'small'} href={'https://www.facebook.com/profile.php?id=100092297245230'} target={'blank'}>
            <FacebookIcon />
          </IconButton>
          <IconButton aria-label="twitter" size={'small'} href={'https://twitter.com/brocherbuilds'} target={'blank'}>
            <TwitterIcon />
          </IconButton>
          <IconButton aria-label="instagram" size={'small'} href={'https://www.linkedin.com/company/brocher-builds'} target={'blank'}>
            <LinkedIcon />
          </IconButton>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Typography
        align={'center'}
        variant={'subtitle2'}
        color="textSecondary"
        gutterBottom
      >
        &copy; BroCher Builds, {new Date().getFullYear()}. All rights reserved
      </Typography>
      <Typography
        align={'center'}
        variant={'caption'}
        color="textSecondary"
        component={'p'}
      >
        When you visit or interact with our sites, services or tools, we or our
        authorised service providers may use cookies for storing information to
        help provide you with a better, faster and safer experience.
      </Typography>
    </Grid>
  </Grid>
);

export default Footer;
