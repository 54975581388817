import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

import 'scss/react-images.scss';
import 'scss/slick-slider.scss';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBNwQQ5Rqlkd3P4QRRPWgC0INKXO9JkkII',
  authDomain: 'brocherbuilds-1abc4.firebaseapp.com',
  projectId: 'brocherbuilds-1abc4',
  storageBucket: 'brocherbuilds-1abc4.appspot.com',
  messagingSenderId: '595717826703',
  appId: '1:595717826703:web:c22a7a3402ec91485268fa',
  measurementId: 'G-CPCHJ0GLHB'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

var useless = analytics;

useless = '2';

const browserHistory = createBrowserHistory();

const App = () => {

  (function () {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }else if (useless == '3') {
        useless = '3';
      }
    };
  })();

  return (
    <Router history={browserHistory}>
      <Routes />
    </Router>
  );
};

export default App;
