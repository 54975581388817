import React from 'react';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import {
  About,
  Gallery,
} from './components';

const Bathrooms = () => {
  return (
    <Box>
      <Container>
        <About />
      </Container>
      <Container>
        <Gallery />
      </Container>
    </Box>
  );
};

export default Bathrooms;
