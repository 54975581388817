export const pages = [
  {
    title: 'Navigation',
    id: 'supporting-pages',
    pages: [
      {
        title: 'Home',
        href: '/',
      },
      {
        title: 'About',
        href: '/about',
      },
      {
        title: 'Portfolio',
        href: '/portfolio',
      },
      {
        title: 'Contact',
        href: '/contact',
      },
      {
        title: '',
        href: '/page-pricing',
      },
      {
        title: '',
        href: '/page-customers',
      },
      {
        title: '',
        href: '/page-hire-us',
      },
      {
        title: '',
        href: '/page-faq',
      },
      {
        title: '',
        href: '/page-privacy',
      },
      {
        title: '',
        href: '/page-coming-soon',
      },
      {
        title: '',
        href: '/page-maintenance-mode',
      },
      {
        title: '',
        href: '/page-cover',
      },
      {
        title: '',
        href: '/page-not-found',
      },
    ],
  },
];
