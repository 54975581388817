/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const About = () => {

  return (
    <Box>
      <Box marginBottom={{ xs: 2, sm: 3, md: 4 }}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'primary'}
          align={'center'}
        >
          Painting
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
          gutterBottom
          data-aos="fade-up"
        >
          Paint Gallery
        </Box>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'textSecondary'}
          align={'center'}
          data-aos="fade-up"
        >
          Our team has a keen eye for detail and the expertise 
          to deliver high-quality painting services that 
          transform any space. From refreshing a room with a 
          new coat of paint to creating custom murals, we are 
          dedicated to bringing your vision to life.
        </Typography>
        <Box
          marginTop={2}
          display={'flex'}
          justifyContent={'center'}
          data-aos="fade-up"
        >
          <Button color={'primary'} variant={'contained'} size={'large'} href={'/contact'}>
            Contact us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
