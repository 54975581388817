import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import HomePageImg from 'svg/img/kitchen-compressed.jpg';
import Container from 'common/Container';
import { Headline } from './components';

const Picture = () => {
  const theme = useTheme();
  return (
    <Box
      position={'relative'}
      sx={{
        backgroundImage:
          `url(${HomePageImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&:after': {
          position: 'absolute',
          content: '" "',
          width: '100%',
          height: '100%',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          background: theme.palette.common.black,
          opacity: 0.2,
        },
      }}
    >
      <Container zIndex={3} position={'relative'}>
        <Container
          marginLeft={'0 !important'}
          maxWidth={{ md: '50% !important' }}
        >
          <Headline />
        </Container>
      </Container>
    </Box>
  );
};

export default Picture;
