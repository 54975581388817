import React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
        >
          Frequently Asked Questions
        </Box>
      </Box>
      <Box>
        {[
          {
            title: 'What types of remodeling services do you offer?',
            subtitle:
              'We offer a wide range of remodeling services, including flooring, painting, kitchen and bathroom remodels, cabinets, countertops, tile work, hardwood, carpeting, framing, siding, roofing, fencing, decks, patios, gazebos, basements, and more.',
          },
          {
            title: 'How do I get started with a remodeling project?',
            subtitle:
              'Getting started is easy! Simply give us a call, send us an email or fill out the form, and we will set up an initial consultation to discuss your project in more detail. From there, we will work with you to create a detailed plan and estimate for the work, and we will begin the project as soon as you are ready to move forward.',
          },
          {
            title: 'How long does a typical remodeling project take?',
            subtitle:
              'The length of your project will depend on a variety of factors, including the scope of the project, the materials involved, and the complexity of the work. However, we always aim to complete projects as quickly as possible without sacrificing quality.',
          },
          {
            title: 'Can I receive a detailed cost estimate for my project, including materials and labor?',
            subtitle:
              'Absolutely! We understand that transparency is important when it comes to project costs, so we are happy to provide you with a detailed estimate that includes all materials and labor needed for your project. Just give us a call or fill out our contact form and we\'ll get started!',
          },
          {
            title: 'Can you work within my budget?',
            subtitle:
              'Yes, we can! We know that every project is unique and we are committed to finding creative solutions that fit your needs and your budget. We will work with you to understand your goals and constraints, and will provide you with options that meet your needs while staying within your budget.',
          },
        ].map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={2}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Faq;
